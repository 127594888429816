import { SearchResponseDto } from '@/models/Dtos/common/searchResultDto';
import { RecruitingUnitRepresentativeTableData } from './RecruitingUnitRepresentativeTableData';
import { TableDataProvider } from '../../models/TableDataProvider';
import { Staff } from '@/models/Entities/Personas/Staff';
import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';

export class RecruitingUnitRepresentativeDataProvider extends TableDataProvider<
  RecruitingUnitRepresentativeTableData,
  Staff,
  SearchStaffDto
> {
  protected transformer = (t: Staff): RecruitingUnitRepresentativeTableData =>
    new RecruitingUnitRepresentativeTableData(t);

  protected queryHandler = async (
    searchDto: SearchStaffDto
  ): Promise<SearchResponseDto<Staff>> => {
    const results: SearchResponseDto<Staff> = await this.store.dispatch(
      'unitRepresentativeModule/fetchWithSelectParameters',
      searchDto
    );
    return results;
  };
}
