var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    { staticClass: "p-4 bg-gray-100" },
    [
      _c("h3", { staticClass: "my-4 ml-4" }, [_vm._v("Unit Hiring PoCs")]),
      _c(
        "div",
        { staticClass: "p-4 mt-8 bg-white border border-gray-200 rounded" },
        [
          _c(
            "async-table-component",
            _vm._g(
              _vm._b(
                {
                  attrs: {
                    isSortable: true,
                    isColumnEditable: true,
                    isFilterable: true,
                    localStorageKey: "unit-rep-collection-table",
                    emptyTableMessage: "0 Unit Hiring PoCs",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "settings-right",
                      fn: function () {
                        return [
                          _c(
                            "button-component",
                            { on: { click: _vm.createUnitRepresentative } },
                            [
                              _c(
                                "div",
                                { staticClass: "flex items-center" },
                                [
                                  _c("icon-component", {
                                    attrs: {
                                      name: "documentText",
                                      width: 13,
                                      height: 14,
                                    },
                                  }),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "pl-2",
                                      attrs: {
                                        "data-cy":
                                          "createUnitRepresentativeButton",
                                      },
                                    },
                                    [_vm._v("Create Unit Hiring PoC")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                },
                "async-table-component",
                _vm.searchService.tableProps,
                false
              ),
              _vm.searchService.tableEventListeners
            )
          ),
        ],
        1
      ),
      _c("create-unit-representative-modal-component", {
        ref: "createUnitRepresentativeModal",
        attrs: { size: "small" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }