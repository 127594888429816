import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import { Staff } from '@/models/Entities/Personas/Staff';
import { ITableConfiguration } from '@/models/Table/Table';
import Routes from '@/router/Routes';
import LinkElementComponent from '../../Elements/LinkElementComponent.vue';
import { TableConfigBuilder } from '../../models/TableConfigBuilder';
import { RecruitingUnitRepresentativeTableData } from './RecruitingUnitRepresentativeTableData';

export const recruitingUnitRepresentativeTableConfigBuilder: TableConfigBuilder<
  RecruitingUnitRepresentativeTableData,
  Staff,
  never,
  SearchStaffDto
> = (): ITableConfiguration<
  RecruitingUnitRepresentativeTableData,
  Staff,
  never
> => {
  return {
    columnConfigurations: [
      {
        columnTitle: 'Last Name',
        render: {
          componentRef: LinkElementComponent,
          componentProps: (
            rowData: RecruitingUnitRepresentativeTableData
          ): Record<string, unknown> => {
            return {
              displayString: rowData.lastName,
              linkTo: {
                name: Routes.UNIT_REPRESENTATIVE_DETAILS,
                params: { id: rowData.id }
              }
            };
          }
        },
        sortKey: 'identificationInformationLastname',
        defaultPinned: true
      },
      {
        columnTitle: 'First Name',
        render: 'firstName',
        sortKey: 'identificationInformationFirstname',
        defaultPinned: true
      },
      {
        columnTitle: 'Email',
        render: 'email',
        sortKey: 'contactInformationEmail',
        defaultPinned: true
      },
      {
        columnTitle: 'Is Archived',
        render: 'isArchived'
      },
      {
        columnTitle: 'Active Applicants',
        render: 'activeApplicants'
      },
      {
        columnTitle: 'Completed Applicants',
        render: 'completedApplicants'
      },
      {
        columnTitle: 'Completed Applications',
        render: 'completedApplications'
      }
    ]
  };
};
