
import { Component, Prop, Vue } from 'vue-property-decorator';
import Modal from '@/components/Modals/ModalComponent.vue';
import DateSelector from '@/components/Forms/Elements/DateSelectorComponent.vue';
import SocialSecurity from '@/components/Forms/Elements/SocialSecurityComponent.vue';
import TextInput from '@/components/Forms/Elements/TextInputComponent.vue';
import PhoneInput from '@/components/Forms/Elements/PhoneInputComponent.vue';
import { IFormState, FormInputState } from '@/models/Forms/FormState';
import Regex from '@/models/Forms/Regex';
import { selectElementContents } from '@/util/download-csv';
import { EUserRoles } from '../../../enums';
import { UnitRepresentative } from '@/models/Entities/Personas/UnitRepresentative';

@Component<CreateUnitRepresentativeModalComponent>({
  components: {
    DateSelector,
    Modal,
    PhoneInput,
    SocialSecurity,
    TextInput
  }
})
export default class CreateUnitRepresentativeModalComponent extends Vue {
  @Prop({ default: '' })
  readonly size!: string;

  unitRepresentative: UnitRepresentative = new UnitRepresentative();
  formState: IFormState = {};
  Regex = Regex;
  show = false;
  resolve: any = () => void 0;
  reject: any = () => void 0;
  isLoading = false;
  inviteLinkUrl = '';
  unitRepresentativeCreated = false;

  close(): void {
    this.show = false;
    this.unitRepresentative = new UnitRepresentative();
    this.unitRepresentativeCreated = false;
    this.inviteLinkUrl = '';
  }

  public open(): Promise<any> {
    this.show = true;
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  async createUnitRepresentative(): Promise<void> {
    this.isLoading = true;
    try {
      const unitRepresentative = await this.$store.dispatch(
        'unitRepresentativeModule/create',
        this.unitRepresentative
      );

      this.inviteLinkUrl = await this.$store.dispatch(
        'userModule/fetchInvitation',
        {
          userEmail: unitRepresentative.contactInformationEmail,
          userRole: EUserRoles.UNIT_REPRESENTATIVE,
          entityId: unitRepresentative.id
        }
      );
      this.unitRepresentativeCreated = true;
    } catch (err: unknown) {
      console.log('error creating unitRepresentative', err);
    }
    this.isLoading = false;
  }

  copyAndClose(): void {
    try {
      const inviteUrlContainer = this.$refs['inviteUrlContainer'];
      selectElementContents(inviteUrlContainer);
      this.resolve(this.unitRepresentative);
    } catch (err: any) {
      this.reject('There was an error copying the Unit Hiring PoC invite URL');
    }
    this.close();
  }

  cancel(): void {
    this.reject();
    this.close();
  }

  updateFormState(inputName: string, inputState: FormInputState): void {
    this.$set(this.formState, inputName, inputState);
  }

  get valid(): boolean {
    return (
      Object.values(this.formState).findIndex((item) => !item.isValid) === -1 &&
      !this.isLoading
    );
  }
}
