
import { Component, Prop, Vue } from 'vue-property-decorator';
import LegacyTableComponent from '@/models/Table/Legacy/LegacyTableComponent.vue';
import ButtonComponent from '@/components/ButtonComponent.vue';
import CreateUnitRepresentativeModalComponent from '@/components/Modals/UnitRepresentative/CreateUnitRepresentativeModalComponent.vue';
import IconComponent from '@/components/IconComponent.vue';
import { UnitRepresentative } from '@/models/Entities/Personas/UnitRepresentative';
import TableSearchService from '@/components/Table/models/TableSearch.service';
import { Staff } from '@/models/Entities/Personas/Staff';
import { RecruitingUnitRepresentativeDataProvider } from '@/components/Table/Staff/RecruitingUnitRepresentative/RecruitingUnitRepresentativeDataProvider';
import { recruitingUnitRepresentativeTableConfigBuilder } from '@/components/Table/Staff/RecruitingUnitRepresentative/RecruitingUnitRepresentativeTableConfigBuilder';
import {
  staffFirstNameFilter,
  staffLastNameFilter,
  staffEmailFilter,
  staffIsArchivedFilter
} from '@/components/Table/Staff/StaffFilters';
import { SearchStaffDto } from '@/models/Dtos/searchStaffDto';
import { RecruitingUnitRepresentativeTableData } from '@/components/Table/Staff/RecruitingUnitRepresentative/RecruitingUnitRepresentativeTableData';
import AsyncTableComponent from '@/components/Table/AsyncTableComponent.vue';
import QuickFilterComponent from '@/components/Table/QuickFilterComponent.vue';
import TableFilterComponent from '@/components/Table/TableFilterComponent.vue';

@Component<UnitRepresentativeCollectionPage>({
  components: {
    ButtonComponent,
    CreateUnitRepresentativeModalComponent,
    LegacyTableComponent,
    IconComponent,
    AsyncTableComponent,
    TableFilterComponent,
    QuickFilterComponent
  }
})
export default class UnitRepresentativeCollectionPage extends Vue {
  @Prop()
  readonly unitRepresentatives!: UnitRepresentative[];

  selectedUnitRepresentatives: UnitRepresentative[] = [];

  searchService = new TableSearchService<
    RecruitingUnitRepresentativeTableData,
    Staff,
    never,
    SearchStaffDto
  >(
    this.$store,
    new RecruitingUnitRepresentativeDataProvider(this.$store),
    new SearchStaffDto(),
    { defaultSortProperty: 'identificationInformationLastname' },
    recruitingUnitRepresentativeTableConfigBuilder,
    {
      filters: [
        staffFirstNameFilter,
        staffLastNameFilter,
        staffEmailFilter,
        staffIsArchivedFilter
      ],
      quickFilters: []
    }
  );
  mounted(): void {
    this.searchService.queryData();
  }

  $refs!: {
    createUnitRepresentativeModal: CreateUnitRepresentativeModalComponent;
  };

  selectRowsHandler(unitRepresentatives: UnitRepresentative[]) {
    this.selectedUnitRepresentatives = unitRepresentatives;
  }

  async createUnitRepresentative() {
    const createUnitRepresentativeModal =
      this.$refs['createUnitRepresentativeModal'];
    try {
      await createUnitRepresentativeModal.open();
      this.$store.dispatch('snackBarModule/enqueue', {
        message: 'Unit hiring Poc created'
      });
      await this.$store.dispatch('unitRepresentativeModule/fetchAll');
    } catch (e) {
      console.log('modal rejected');
    }
  }
}
