import { Staff } from '@/models/Entities/Personas/Staff';
import { ITableData } from '../../models/TableData';

export class RecruitingUnitRepresentativeTableData implements ITableData {
  entityId: number;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  isArchived: boolean;
  activeApplicants: number;
  completedApplicants: number;
  completedApplications: number;

  constructor(staffMember: Staff) {
    this.entityId = staffMember.id;
    this.firstName = staffMember.identificationInformationFirstname;
    this.lastName = staffMember.identificationInformationLastname;
    this.email = staffMember.contactInformationEmail;
    this.isArchived = staffMember.isArchived;
    this.id = staffMember.id;
    this.activeApplicants = 0;
    this.completedApplicants = 0;
    this.completedApplications = 0;
  }
}
